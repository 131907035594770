import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import { getQuickAddCartInstance, canUseNewQuickAdd, plugins } from 'public/src/pages/components/product/quick_add_v3/common'
import { cartUpdateAttr } from 'public/src/pages/common/add-on/utils/fetcher'

/**
 * @description: open quick add in pwa used by business-cart component
 * @param {Object} ctx business-cart ctx, with { type, item, callBacks }
 * @param {Object} quickAdd pwa quickAdd Object with open function
 * @param {Object} daEventCenter pwa daEventCenter with triggerNotice function
 * @return {*}
 */
export async function openQuick4BCart(ctx = {}, quickAdd, daEventCenter, excludePlugins = []) {
  const item = ctx.item
  const callBacks = ctx.callBacks
  const sku_sale_attr = item.product?.sku_sale_attr || {}
  let selectedAttrIndex = {}
  if(item.attr?.attr_id) {
    selectedAttrIndex[item.attr?.attr_id] = { 
      attr_value_id: item.attr?.attr_value_id
    }
  }
  sku_sale_attr && sku_sale_attr.forEach(item => {
    if(!item.is_main){
      selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
    }
  })
  const imgRatio = await getImageRatio(item.product?.goods_img)
  daEventCenter.addSubscriber({ modulecode: '1-8-3' })
  daEventCenter.addSubscriber({ modulecode: '1-8-1' })
  if (canUseNewQuickAdd('cart')) {
    getQuickAddCartInstance().open(
      {
        goods_id: item.product?.goods_id || '',
        mallCode: item.mall_code,
      },
      {
        plugins: [plugins.salesAttrUpdatePlugin(async context => {
          try {
            const reqParams = {
              is_checked: item.is_checked,
              append_id_list: item.appendIds,
              sku_code: context?.skuInfo?.sku_code,
              id: item.id,
              mall_code: context?.mallCode,
              quantity: context?.quantity,
              goods_id: context?.productInfo?.goods_id,
            }
            const res = await cartUpdateAttr(reqParams)
            daEventCenter.triggerNotice({
              daId: '1-8-1-19',
              extraData: {
                update_result: res.code === '0' ? 1 : 0,
                sku_id: item.product.goods_sn,
                mall_code: item.mall_code,
              }
            })
            callBacks?.editComplete?.({ res })
          } catch (e) {
            console.error(e)
          }
        })],
        excludePlugins,
        salesAttrConfig: {
          selectedAttrIndex
        },
        analysisConfig: {
          code: 'cart',
          from: 'cart',
          source: 'page',
        },
        extendedParam: {
          imgRatio,
          quantity: item.quantity,
        },
        featureConfig: {
          footerBar: {
            // 是否需要收藏按钮
            needFavoriteButton: false,
          },
        },
        callbacks: {
          onDrawerStatusChange: (show) => {
            show && callBacks?.daTriggerNotice({
              daId: '8',
              extraData: {
                sku_id: item.product?.goods_sn,
              }
            })
          },
          onMallClick: () => {
            daEventCenter.triggerNotice({
              daId: '1-8-3-12',
              extraData: {
                sku_id: item.product?.goods_sn
              }
            })
          }
        }
      }
    )
  } else {
    quickAdd.open({
      goods_id: item.product?.goods_id || '',
      imgRatio,
      selectedAttrIndex,
      mallCode: item.mall_code,
      isShowEdit: true,
      isShowWishBtn: false,
      item_id: item.id,
      quantity: item.quantity,
      clickCallBack: {
        edit: callBacks.editComplete,
        mallClick: () => {
          daEventCenter.triggerNotice({
            daId: '1-8-3-12',
            extraData: {
              sku_id: item.product?.goods_sn
            }
          })
        },
        isActive: (show) => {
          show && callBacks?.daTriggerNotice({
            daId: '8',
            extraData: {
              sku_id: item.product?.goods_sn,
            }
          })
        }
      },
      analysisConfig: {
        code: 'cart',
        from: 'cart',
        source: 'page',
      },
      showPromotionTagInfo: false,
    })
  }
}
