<template>
  <span 
    class="price-count-to" 
    v-html="displayText"
  >
  </span>
</template>

<script>
const amountWithSymbolTransform = (amountWithSymbol = '', currencyDecPointSymbol = '') => {
  if ( !amountWithSymbol ) return ''
  if(!currencyDecPointSymbol && typeof window != 'undefined') {
    let { currency, currencies, lang, appLanguage } = gbCommonInfo
    let currencyObj = currencies?.[lang]?.[currency] || {}
    let { separator_multi_resp_list = [] } = currencyObj || {}
    let existSeparator = separator_multi_resp_list?.filter(item => item?.language_flag == appLanguage)
    if (existSeparator?.length) {
      currencyDecPointSymbol = existSeparator?.[0]?.dec_point_symbol || '.'
    } else {
      currencyDecPointSymbol = currencyObj?.dec_point_symbol || '.'
    }
  }
  let hasDecPointSymbol = amountWithSymbol.includes(currencyDecPointSymbol)
  let reg = hasDecPointSymbol ? new RegExp(`^(\\D*)(.*?)([\\${currencyDecPointSymbol}])(\\d*?)(\\D*)$`, 'g') : new RegExp(`^(\\D*)(.*?)(\\D*)$`, 'g')
  let result = amountWithSymbol.replace(reg, function () {
    let currencyStr = RegExp.$1 || (hasDecPointSymbol ? RegExp.$5 : RegExp.$3) ? `<span class="price-currency">${ RegExp.$1 || (hasDecPointSymbol ? RegExp.$5 : RegExp.$3) }</span>` : ''
    let str = `<span class="price-amount-decimal">`
    str = str + (RegExp.$2 ? `<span class="price-amount">${ RegExp.$2 }</span>` : '')
    str = str + (hasDecPointSymbol && RegExp.$3 ? `<span>${ RegExp.$3 }</span>` : '')
    str = str + (RegExp.$4 ? `<span class="price-decimal">${ RegExp.$4 }</span>` : '')
    str = str + `</span>`
    return RegExp.$1 ? currencyStr + str : str + currencyStr
  })
  return result
}
const isSsr = () => {
  return typeof window === 'undefined'
}
const toNumber = (v) => {
  if (isNaN(Number(v))) return 0
  return Number(v)
}

export default {
  name: 'CountTo',
  props: {
    amount: {
      type: [String, Number],
      default: ''
    },
    duration: {
      type: Number,
      required: false,
      default: 300
    },
  },
  data() {
    return {
      displayText: '',
      raf: null,
      
      startTime: null, // 开始时间
      curAmount: 0, // 当前数值
      preAmount: 0, // 上次数值
    }
  },
  computed: {
    isUp() {
      return this.curAmount > this.preAmount
    }
  },
  watch: {
    amount: {
      handler(val, oldVal) {
        this.curAmount = toNumber(val)
        this.preAmount = toNumber(oldVal)
        this.start()
      },
      immediate: true,
    }
  },
  beforeDestroy() {
    window.cancelAnimationFrame(this.count)
  },
  methods: {
    start() {
      if (isSsr()) return
      
      this.reset()
      window.requestAnimationFrame(this.count)
    },
    count(timestamp) { // timestamp: 前一帧渲染的结束时间 ms
      if (!this.startTime) this.startTime = timestamp
      const progress = timestamp - this.startTime
      
      let temp = this.preAmount

      if (this.isUp) {
        temp += Math.abs(this.curAmount - this.preAmount) * (progress / this.duration)
        temp = temp > this.curAmount ? this.curAmount : temp
      } else {
        temp -= Math.abs(this.curAmount - this.preAmount) * (progress / this.duration)
        temp = temp < this.curAmount ? this.curAmount : temp
      }

      this.displayText = this.formatAmount(temp)
      if (progress < this.duration) {
        this.raf = window.requestAnimationFrame(this.count)
      } else {
        // 结束后使用接口返回的字段去显示
        this.displayText = this.formatAmount(this.amount)
        this.reset()
      }
    },
    formatAmount(val) {
      const rule = window.getCurrencyRule?.()
      return this.formatHtmlAmount(window.GB_transform_price_symbol?.(val, '', rule))
    },
    formatHtmlAmount(val) {
      const rule = window.getCurrencyRule?.()
      return amountWithSymbolTransform(val || '', rule?.dec_point_symbol)
    },
    reset() {
      this.startTime = null
      window.cancelAnimationFrame(this.count)
    }
  },
}
</script>

<style scoped lang="less">
.price-count-to {
  display: inline-flex;
  align-items: baseline;
}
</style>
